.App {
  text-align: center;
  font-family: 'Cairo', sans-serif;

}


.nav-link{
  font-size: 1rem;
  color: grey;
  text-decoration: none;
  margin: 1%;
  padding-left: 2%;
  padding-right: 1%;
  outline: none;
  white-space: nowrap;
}
.nav-link:hover{
  text-shadow: 0 0 10px #fd9827;
  color: white;
};
