.postWrap {
  width: 90%;
  margin: 20px 5% 0 5%;
  background-color: black;
  border: 3px solid #fd9827;
  border-radius: 10px;
  color: white;
}

.formStyle {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  /* text-align: left; */
}

input {
  padding: 8px;
  margin: 10px 0;
  font-size: 1rem;
}

/* .postButton {
  width: 150px;
  background-color: black;
  border: 3px solid #fd9827;
  border-radius: 10px;
  color: #fd9827;
  font-size: 1rem;
  padding: 4px;
  margin: 30px 0 30px 0;
} */
.button-container{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 3%;
  margin: 1%;
}

.area {
  min-height: 140px;
}

select {
  padding: 5px 55px;
  font-size: 1.2rem;
  margin: 10px 0;
  border-radius: 10px;
  border: 3px solid #fd9827;
  width: 230px;
  text-align: left;
  background-color: black;
  color: white;
  outline: none;
}

input{
  border-radius: 10px;
  width: 210px;
  background-color: black;
  color: white;
  border: 3px solid #fd9827;
  outline: none;
  text-align: center;
}
::placeholder{
  text-align: center;
}

